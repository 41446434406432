<template>
  <div>
    <div class="header">
      <img
        class="logo"
        height="35%"
        :src="require('@/assets/imgs/logo.png')"
      >
    </div>

    <Lang @changeLang="changeLang" />
    <div class="app-container">
      <StepContainer v-if="!policyDetail" :step="step" />

      <Category v-if="step === 0" v-model="form.productCategory" />
      <ReportBy v-if="step === 1" v-model="form" />
      <PolicyList v-if="step === 2" v-model="form" />
      <ReportInfo v-if="step === 3" v-model="form" />
    </div>
  </div>

</template>

<script>
import StepContainer from './components/step'
import Category from './components/category/index.vue'
import ReportBy from './components/reportBy/index.vue'
import PolicyList from './components/policyList/index.vue'
import ReportInfo from './components/reporterInfo/index.vue'
import CryptoJS from 'crypto-js'
import Dao from '@/utils/dao'
import Lang from '@/views/inspectionCustomer/component/lang/index.vue'

export default {
  components: { StepContainer, Category, ReportBy, PolicyList, ReportInfo, Lang },
  props: {},
  data() {
    return {
      lang: this.$store.state.base.lang,
      routeQuery: {},
      reshow: false,
      form: {
        productCategory: '', // car
        reportBy: {
          // plateCode: 'BA',
          // plateNo: '136 CEK',
          // vehicleEngineNo: 'ENGINEMOBILCEKPREMI136',
          // vehicleVinNo: 'CHASSISMOBILCEKPREMI136'
        },
        slipUidList: [], // 1773243114199007233
        reportInfo: {
          reporter: '',
          reporterMobile: '',
          reporterEmail: ''
        }
      },
      step: 0
    }
  },
  computed: {
    policyDetail() {
      return this.$route.query.slipUid
    }
  },
  provide() {
    return {
      reshow: () => { return this.reshow }
    }
  },
  watch: {
    step(newval, oldval) {
      if (oldval == 2 && newval == 1) {
        this.reshow = true
      } else {
        this.reshow = false
      }
    }
  },
  activated() {
    this.initParams()
    this.init()
  },
  methods: {
    initParams() {
      const { formNo, tenantId, tempVisitToken } = this.$route.query
      this.routeQuery = {
        formNo: this.decryptByAES(formNo),
        tenantId: this.decryptByAES(tenantId),
        tempVisitToken: this.decryptByAES(tempVisitToken)
      }
    },
    decryptByAES(cipherText, keyInBase64Str = '489E7BA7228D49589364C5C9EB54268E') {
      const key = CryptoJS.enc.Base64.parse(keyInBase64Str)
      // 返回的是一个Word Array Object，其实就是Java里的字节数组
      const decrypted = CryptoJS.AES.decrypt(cipherText, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })

      return decrypted.toString(CryptoJS.enc.Utf8)
    },
    init() {
      const { tempVisitToken } = this.routeQuery
      Dao.set('tempToken', tempVisitToken)
      if (!this.policyDetail) return
      this.step = 3
    },
    changeLang(val) {
      this.lang = val
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  padding: 10px 10px 80px;
}
.header {
  padding: 0 20px;
  height: 100px;
  line-height: 75px;
  background-color: #D90109;
  font-size: 0;
  text-align: center;
}
</style>
